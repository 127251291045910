import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";
import SeoServices from "../components/SeoServices";
import BlogRoll from "../components/BlogRoll";

export const IndexPageTemplate = ({
  image,
  imageMobile,
  title,
  contact,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  services,
}) => (
  <div>
    <h1 className="title is-title is-size-1-tablet has-text-weight-bold has-text-centered">
      PRÓXIMAMENTE
    </h1>
    <p className="subtitle is-size-4-tablet has-margin-top-20 is-family-primary has-text-centered">
      Estamos trabajando en una nueva versión de nuestra web
    </p>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageMobile: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  contact: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  services: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.array,
  }),
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout >
      <IndexPageTemplate
        image={frontmatter.image}
        imageMobile={frontmatter.imageMobile}
        title={frontmatter.title}
        contact={frontmatter.contact}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        services={frontmatter.services}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        contact
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageMobile {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title          
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 440, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            icon
            title
            text
          }
          heading
          description
        }
        services {
          title
          items {
            image {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 440, quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            title
            description
          }
        }
      }
    }
  }
`;
