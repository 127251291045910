import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item, index) => (      
      <div key={index} className="column is-4">        
        <section className="feature has-text-centered">
          <div className="has-text-centered">
            <div>              
              <span className="icon has-text-info">
                <i className={`fas ${item.icon}`} style={{
                  fontSize: '4em',
                  paddingBottom: '1em'
                }}></i>
              </span>
            </div>
          </div>
          <h3 className="is-size-4" style={{
            padding: '0.5em 0'
          }}>
            {item.title}
          </h3>
          <p>{item.text}</p>
        </section>
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
